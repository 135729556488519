import { useFetchData } from "@thinkvn/composables/lib/useFetchData";
import ProductService from "@/modules/Goods/service/product.service";
import PromotionService from "@/modules/Promotion/service";
import { createSharedComposable } from "@vueuse/core";

export const useProductData = createSharedComposable(() => {
  const { isFetching, fetch, data: productItems } = useFetchData(
    () => ProductService.getAllSku(),
    {
      transform: response => response.data
    }
  );

  fetch();

  return {
    isFetching,
    fetch,
    productItems
  };
});

export const usePromotionData = createSharedComposable(() => {
  const { isFetching, fetch, data: promotions } = useFetchData(
    () => {
      return PromotionService.getAllPromotions();
    },
    {
      transform: response => response.data,
      initialData: []
    }
  );

  fetch();

  return {
    isFetching,
    fetch,
    promotions
  };
});
