<template>
  <div class="item point-editable">
    <div
      :class="
        index % 5 === 0
          ? 'shoppable-image__aspect-ratio-box'
          : index % 5 === 2 || index % 5 === 3
          ? 'shoppable-image__aspect-ratio-portrait'
          : 'shoppable-image__aspect-ratio-square'
      "
      class="item-wrapper"
    >
      <img
        :src="getImageUrl(internalImage.url)"
        alt=""
        class="item__image"
        @click="onClickImage"
      />

      <div
        v-if="currentPoint"
        :style="{ left: currentPoint.left, top: currentPoint.top }"
        class="item__point"
      ></div>

      <div
        v-for="(point, index) in internalImage.points"
        :key="point.sku_id + ' - ' + index"
        :style="{ left: point.left, top: point.top }"
        class="item__point"
        @click="onClickPoint(point)"
      ></div>
    </div>

    <modal-select-product
      :point="currentPoint"
      :productItems="productItems"
      :visible.sync="visible"
      @change="onSelectProduct"
      @close="currentPoint = null"
      @remove="onRemovePoint(currentPoint)"
    ></modal-select-product>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { useVisible } from "@thinkvn/composables/lib/useVisible";
import ModalSelectProduct from "./modal-select-product.vue";
import {
  useProductData,
  usePromotionData
} from "@/modules/Website/pages/Shoppable/composables";

export default defineComponent({
  name: "ImageViewConfig",

  components: {
    ModalImageInformation: () => import("./modal-image-information.vue"),
    ModalSelectProduct
  },

  props: {
    image: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  setup(props) {
    const { visible, open } = useVisible();
    const currentPoint = ref(null);
    const internalImage = ref(props.image);
    const { productItems: allProductItems } = useProductData();
    const { promotions } = usePromotionData();

    const productItems = computed(() => {
      if (internalImage.value.promotion_id) {
        const _activatedPromotion = promotions.value.find(
          _promotion => _promotion.id === internalImage.value.promotion_id
        );

        return _activatedPromotion ? _activatedPromotion.product_items : [];
      }

      return allProductItems.value;
    });

    const toUnit = value => value + "%";

    const onClickImage = async e => {
      const target = e.target;

      const { clientWidth, clientHeight } = target;
      const { offsetX, offsetY } = e;

      const left = (offsetX / clientWidth) * 100;
      const top = (offsetY / clientHeight) * 100;

      currentPoint.value = {
        left: toUnit(left),
        top: toUnit(top),
        sku_id: null
      };

      open();
    };

    const onSelectProduct = sku_id => {
      if (sku_id) {
        props.image.points.push({
          ...currentPoint.value,
          sku_id
        });
      }

      currentPoint.value = null;
    };

    const onClickPoint = point => {
      currentPoint.value = point;

      open();
    };

    const onRemovePoint = currentPoint => {
      props.image.points = props.image.points.filter(point => {
        return (
          point.left !== currentPoint.left && point.top !== currentPoint.top
        );
      });
    };

    return {
      visible,
      open,
      productItems,
      internalImage,
      currentPoint,
      onClickImage,
      onSelectProduct,
      onClickPoint,
      onRemovePoint
    };
  },

  methods: {
    getImageUrl(url) {
      if (typeof url === "string") return url;

      return URL.createObjectURL(url);
    }
  }
});
</script>

<style scoped>
.item {
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: #f6f6f6;
  width: 300px;
  position: relative;
  display: inline-flex;
}

.item-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.shoppable-image__aspect-ratio-box {
  padding-bottom: 113.333333%;
}

.shoppable-image__aspect-ratio-square {
  padding-bottom: 100%;
}

.shoppable-image__aspect-ratio-portrait {
  padding-bottom: 133.333333%;
}

.item__image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
}

.item.point-editable .item__image {
  cursor: crosshair;
}

.item.point-editable .item__point {
  cursor: pointer;
}

.item__point {
  position: absolute;
  width: 32px;
  height: 32px;
  border: 1px solid #f5f5f5;
  background: rgba(43, 43, 43, 0.5);
  border-radius: 100%;
  transform: translate(-50%, -50%);
  transition: all 0.2s;

  &:before {
    content: "";
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 100%;
    display: inline-flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.2s;
  }

  &:hover {
    background: rgba(43, 43, 43, 0.8);

    &:before {
      width: 10px;
      height: 10px;
    }
  }
}
</style>
