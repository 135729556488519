<template>
  <v-card class="pa-4 w-full overflow-y-auto" flat>
    <div class="d-flex align-center">
      <span class="text-h6 font-weight-bold">Shoppable Images</span>

      <v-spacer></v-spacer>

      <v-btn v-if="false" color="primary" outlined>
        <v-icon>mdi-eye</v-icon>
        <span>Xem trước</span>
      </v-btn>
    </div>

    <div class="mt-4">
      <span class="font-weight-bold">Thư viện</span>

      <div class="mt-2 d-flex flex-wrap align-center" style="gap: 4px">
        <library-item
          v-for="(library, index) in libraries"
          :key="'item-' + library.id"
          :is-active="activatedId === library.id"
          :library.sync="libraries[index]"
          @click="activatedId = library.id"
          @removed="onRemoveLibrary(library)"
        ></library-item>

        <btn-add-library @updated="onAddedLibrary"></btn-add-library>
      </div>
    </div>

    <div class="mt-4">
      <div v-for="(library, index) in libraries" :key="library.id">
        <template v-if="activatedId === library.id">
          <library-image-item
            :library.sync="libraries[index]"
          ></library-image-item>
        </template>
      </div>
    </div>
  </v-card>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useFetchData } from "@thinkvn/composables/lib/useFetchData";
import { last } from "@vt7/utils";
import ShoppableService from "../services";
import BtnAddLibrary from "../components/btn-add-library.vue";
import LibraryItem from "@/modules/Website/pages/Shoppable/components/library-item.vue";
import CardInputFile from "@/modules/Website/pages/Shoppable/components/card-input-file.vue";
import LibraryImageItem from "@/modules/Website/pages/Shoppable/components/library-image-item.vue";

export default defineComponent({
  components: { LibraryImageItem, CardInputFile, LibraryItem, BtnAddLibrary },

  setup() {
    const activatedId = ref(null);
    const { data: libraries, fetch } = useFetchData(
      () => {
        return ShoppableService.getAllLibrary();
      },
      {
        initialData: [],
        transform: response => response.data
      }
    );
    fetch();

    const onAddedLibrary = async () => {
      await fetch();

      if (libraries.value.length) {
        activatedId.value = last(libraries.value).id;
      }
    };

    const onRemoveLibrary = library => {
      libraries.value = libraries.value.filter(_lib => _lib.id !== library.id);
    };

    return {
      activatedId,
      libraries,
      fetch,
      onAddedLibrary,
      onRemoveLibrary
    };
  }
});
</script>
