<template>
  <div>
    <div
      :class="{ 'is-active': isActive }"
      class="library-item"
      @click="$emit('click')"
    >
      <div
        style="width: 32px; height: 32px; border-radius: 9999px; overflow: hidden"
      >
        <img
          :src="library.thumbnail"
          alt=""
          style="width: 100%; height: 100%; object-fit: cover"
        />
      </div>

      <span class="ml-2">{{ library.name }}</span>

      <v-icon v-if="isActive" class="ml-2" color="primary" small @click="open"
        >mdi-pencil
      </v-icon>
    </div>

    <v-dialog v-model="visible" :width="'800px'" max-width="90%">
      <v-card>
        <v-card-title>
          <div class="font-weight-bold">Cập nhật thư viện</div>

          <v-spacer></v-spacer>

          <v-btn
            :loading="isLoading"
            class="mr-2"
            color="primary"
            depressed
            @click="mutate"
            >Lưu
          </v-btn>

          <v-btn
            :loading="isLoading"
            color="primary"
            depressed
            @click="onDeleteLibrary"
            >Xoá
          </v-btn>
        </v-card-title>

        <v-form ref="formRef" lazy-validation>
          <div class="pa-4 mt-4">
            <div>
              <div class="font-weight-bold mb-2">Tên thư viện</div>

              <tp-text-field
                v-model="internalLibrary.name"
                background-color="white"
                class="text-body-1"
                dense
                filled
                outlined
                placeholder="Nhập tên thư viện"
                single-line
                validate="required"
              ></tp-text-field>
            </div>

            <div class="d-flex">
              <div class="mr-6">
                <div class="font-weight-bold mb-2">Ảnh đại diện</div>

                <div class="">
                  <tp-input-image
                    :maxFileSize="300 * 1024"
                    :src.sync="internalLibrary.thumbnail"
                    allowCompress
                    allowFileSize
                    allowFileTypeValidation
                    class="flex-grow-1"
                    forceType="png"
                    required
                  ></tp-input-image>
                </div>
              </div>
            </div>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, ref } from "vue";
import { cloneDeep, toFormData, omit } from "@vt7/utils";
import { useVisible } from "@thinkvn/composables/lib/useVisible";
import { useMutation } from "@thinkvn/composables/lib/useMutation";
import ShoppableService from "@/modules/Website/pages/Shoppable/services";
import { showToastActionAlert } from "@/core/composables";

export default defineComponent({
  props: {
    library: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const context = getCurrentInstance().proxy;
    const formRef = ref(null);
    const { visible, open, close } = useVisible();

    const internalLibrary = ref(cloneDeep(props.library));

    const { mutate, isLoading } = useMutation(
      () => {
        const _library = omit(internalLibrary.value, "images");
        const formData = toFormData(_library);

        if (internalLibrary.value.id) {
          return ShoppableService.updateLibrary(formData);
        } else {
          return ShoppableService.addLibrary(formData);
        }
      },
      {
        onValidate() {
          return formRef.value.validate();
        },
        onSuccess(response) {
          const data = response.data;
          emit("updated", data);
          emit("update:library", data);
          internalLibrary.value = data;

          close();

          showToastActionAlert(context, {
            message: "Thành công"
          });
        },
        onError(e) {
          showToastActionAlert(context, {
            message: e.message || e.response.data.message
          });
        }
      }
    );

    const onDeleteLibrary = async () => {
      context.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xác nhận xoá thư viện <strong>${internalLibrary.value.name}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              await ShoppableService.deleteLibrary(internalLibrary.value.id);
              emit("removed");
              close();
              showToastActionAlert(context, {
                message: "Xoá thư viện thành công"
              });
            }
          }
        }
      });
    };

    return {
      visible,
      open,
      close,

      formRef,

      internalLibrary,
      mutate,
      isLoading,
      onDeleteLibrary
    };
  }
});
</script>

<style scoped>
.library-item {
  padding: 8px 16px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  cursor: pointer;
}

.library-item.is-active {
  border: 1px solid #246fff;
  color: #246fff;
  background: #fff;
}
</style>
