<template>
  <div class="item">
    <div
      :class="
        index % 5 === 0
          ? 'shoppable-image__aspect-ratio-box'
          : index % 5 === 2 || index % 5 === 3
          ? 'shoppable-image__aspect-ratio-portrait'
          : 'shoppable-image__aspect-ratio-square'
      "
      class="item-wrapper"
    >
      <v-btn class="icon-edit" icon small @click="visible = true">
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>

      <img :src="getImageUrl(internalImage.url)" alt="" class="item__image" />

      <div
        v-for="point in internalImage.points"
        :key="point.sku_id"
        :style="{ left: point.left, top: point.top }"
        class="item__point"
      ></div>
    </div>

    <modal-image-information
      :image.sync="internalImage"
      :index="index"
      :visible.sync="visible"
      @delete="onDeleteImage"
      @update="onUpdateImage"
    ></modal-image-information>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, ref } from "vue";
import { omit, toFormData } from "@vt7/utils";
import { useVisible } from "@thinkvn/composables/lib/useVisible";
import { useMutation } from "@thinkvn/composables/lib/useMutation";
import ShoppableService from "@/modules/Website/pages/Shoppable/services";
import ModalSelectProduct from "./modal-select-product.vue";
import { showToastActionAlert } from "@/core/composables";

export default defineComponent({
  name: "ImageViewConfig",

  components: {
    ModalImageInformation: () => import("./modal-image-information.vue"),
    ModalSelectProduct
  },

  props: {
    image: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      default: null
    },
    library: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const context = getCurrentInstance().proxy;
    const { visible, open } = useVisible();
    const internalImage = ref(props.image);

    const { mutate: onUpdateImage } = useMutation(
      image => {
        const _formData = toFormData(omit(image, "points"));
        _formData.append(
          "points",
          JSON.stringify(
            image.points.map(point => ({
              top: point.top,
              left: point.left,
              sku_id: point.sku_id
            }))
          )
        );

        return ShoppableService.updateImage(_formData);
      },
      {
        onSuccess() {
          visible.value = false;
        },
        onError(e) {
          showToastActionAlert(context, {
            message: e.message || e.response.data.message
          });
        }
      }
    );

    const onDeleteImage = async removeableImage => {
      try {
        await ShoppableService.deleteImage(removeableImage.id);

        props.library.images = props.library.images.filter(
          image => image.id !== removeableImage.id
        );

        showToastActionAlert(context, {
          message: "Thành công"
        });
      } catch (e) {
        showToastActionAlert(context, {
          message: e.message || e.response.data.message
        });
      }
    };

    return {
      visible,
      open,
      internalImage,
      onUpdateImage,
      onDeleteImage
    };
  },

  methods: {
    getImageUrl(url) {
      if (typeof url === "string") return url;

      return URL.createObjectURL(url);
    }
  }
});
</script>

<style scoped>
.item {
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: #f6f6f6;
  width: 300px;
  position: relative;
  display: inline-flex;

  &:hover .icon-edit {
    opacity: 1;
  }
}

.icon-edit {
  position: absolute;
  right: 8px;
  top: 8px;
  opacity: 0;
  transition: all 0.2s;
  background: rgba(255, 255, 255, 0.5);
  z-index: 10;
}

.item-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.shoppable-image__aspect-ratio-box {
  padding-bottom: 113.333333%;
}

.shoppable-image__aspect-ratio-square {
  padding-bottom: 100%;
}

.shoppable-image__aspect-ratio-portrait {
  padding-bottom: 133.333333%;
}

.item__image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
}

.item__point {
  position: absolute;
  width: 32px;
  height: 32px;
  border: 1px solid #f5f5f5;
  background: rgba(43, 43, 43, 0.5);
  border-radius: 100%;
  transform: translate(-50%, -50%);
  transition: all 0.2s;

  &:before {
    content: "";
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 100%;
    display: inline-flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.2s;
  }

  &:hover {
    background: rgba(43, 43, 43, 0.8);

    &:before {
      width: 10px;
      height: 10px;
    }
  }
}
</style>
