<template>
  <div>
    <label class="card-input-file" for="input-file">
      <v-icon x-large>mdi-file-image-outline</v-icon>

      <span class="mt-4">Thêm ảnh mới</span>

      <input
        id="input-file"
        accept=".jpg,.jpeg,.png"
        type="file"
        @input="onChangeFile"
      />
    </label>

    <modal-image-information
      :key="newImage.url && newImage.url.lastModified"
      :image.sync="newImage"
      :index="library.images.length"
      :visible.sync="visible"
      @update="onAddImage"
    ></modal-image-information>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance } from "vue";
import { useVisible } from "@thinkvn/composables/lib/useVisible";
import { autoResetRef } from "@thinkvn/composables/lib/autoResetRef";
import { useMutation } from "@thinkvn/composables/lib/useMutation";
import ModalImageInformation from "./modal-image-information.vue";
import ShoppableService from "../services";
import { showToastActionAlert } from "@/core/composables";

export default defineComponent({
  components: { ModalImageInformation },

  props: {
    library: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const context = getCurrentInstance().proxy;
    const { visible, open, close } = useVisible();

    const [newImage, reset] = autoResetRef(() => ({
      library_id: props.library.id,
      title: "",
      url: null,
      points: [],
      promotion_id: null,
      time_from: "",
      time_to: ""
    }));

    const onChangeFile = e => {
      const _file = e.target.files[0];
      console.log("_file", _file);
      if (_file) {
        newImage.value.url = _file;
        open();
      }

      e.target.value = "";
      e.target.files = null;
    };

    const { mutate: onAddImage } = useMutation(
      () => {
        const _formData = new FormData();
        _formData.append("library_id", newImage.value.library_id);
        _formData.append("title", newImage.value.title);
        _formData.append("url", newImage.value.url);
        _formData.append("points", JSON.stringify(newImage.value.points));
        _formData.append("promotion_id", newImage.value.promotion_id);
        _formData.append("time_from", newImage.value.time_from);
        _formData.append("time_to", newImage.value.time_to);

        return ShoppableService.addImage(_formData);
      },
      {
        onSuccess(response) {
          reset();
          close();
          props.library.images.push(response.data);
        },
        onError(e) {
          showToastActionAlert(context, {
            message: e.message || e.response.data.message
          });
        }
      }
    );

    return {
      visible,
      open,
      close,
      onChangeFile,
      newImage,
      onAddImage
    };
  }
});
</script>

<style scoped>
.card-input-file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: #f6f6f6;
  height: 400px;
  width: 300px;
  cursor: pointer;
}

.card-input-file input {
  display: none;
}
</style>
