<template>
  <draggable
    :animation="200"
    :value="library.images"
    class="d-flex align-start flex-wrap"
    draggable=".item"
    style="gap: 8px;"
    @input="onInput"
  >
    <template #header>
      <card-input-file :library="library"></card-input-file>
    </template>

    <image-viewer
      v-for="(image, index) in library.images"
      :key="image.id"
      :image="image"
      :index="index"
      :library="library"
    ></image-viewer>
  </draggable>
</template>

<script>
import { defineComponent, getCurrentInstance } from "vue";
import draggable from "vuedraggable";
import CardInputFile from "./card-input-file.vue";
import ImageViewer from "./image-viewer.vue";
import { useMutation } from "@thinkvn/composables/lib/useMutation";
import { pick } from "@vt7/utils";
import ShoppableService from "@/modules/Website/pages/Shoppable/services";
import { showToastActionAlert } from "@/core/composables";

export default defineComponent({
  components: { draggable, ImageViewer, CardInputFile },

  props: {
    library: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const context = getCurrentInstance().proxy;

    const { mutate } = useMutation(
      images => {
        return ShoppableService.updateLibrary({
          ...props.library,
          images: images.map(image => {
            return {
              ...pick(
                image,
                "id",
                "library_id",
                "promotion_id",
                "time_from",
                "time_to",
                "title",
                "url"
              ),
              points: image.points.map(point => ({
                top: point.top,
                left: point.left,
                sku_id: point.sku_id
              }))
            };
          })
        });
      },
      {
        onError(e) {
          showToastActionAlert(context, {
            message: e.message || e.response.data.message
          });
        }
      }
    );

    const onInput = data => {
      props.library.images = data;

      mutate(data);
    };

    return {
      onInput
    };
  }
});
</script>
