<template>
  <div>
    <v-btn color="primary" depressed @click="open">
      <v-icon left>mdi-plus</v-icon>
      Thêm thư viện
    </v-btn>

    <v-dialog v-model="visible" :width="'800px'" max-width="90%">
      <v-card>
        <v-card-title>
          <div class="font-weight-bold">Thêm mới thư viện</div>

          <v-spacer></v-spacer>

          <v-btn :loading="isLoading" color="primary" depressed @click="mutate"
            >Thêm
          </v-btn>
        </v-card-title>

        <v-form ref="formRef" lazy-validation>
          <div class="pa-4 mt-4">
            <div>
              <div class="font-weight-bold mb-2">Tên thư viện</div>

              <tp-text-field
                v-model="library.name"
                background-color="white"
                class="text-body-1"
                dense
                filled
                outlined
                placeholder="Nhập tên thư viện"
                single-line
                validate="required"
              ></tp-text-field>
            </div>

            <div class="d-flex">
              <div class="mr-6">
                <div class="font-weight-bold mb-2">Ảnh đại diện</div>

                <div class="">
                  <tp-input-image
                    :maxFileSize="300 * 1024"
                    :src.sync="library.thumbnail"
                    allowCompress
                    allowFileSize
                    allowFileTypeValidation
                    class="flex-grow-1"
                    forceType="png"
                    required
                  ></tp-input-image>
                </div>
              </div>
            </div>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, ref } from "vue";
import { toFormData } from "@vt7/utils";
import { useVisible } from "@thinkvn/composables/lib/useVisible";
import { useMutation } from "@thinkvn/composables/lib/useMutation";
import { autoResetRef } from "@thinkvn/composables/lib/autoResetRef";
import ShoppableService from "@/modules/Website/pages/Shoppable/services";
import { showToastActionAlert } from "@/core/composables";

export default defineComponent({
  setup(_, { emit }) {
    const context = getCurrentInstance().proxy;
    const formRef = ref(null);
    const { visible, open, close } = useVisible();

    const [library, reset] = autoResetRef(() => {
      return {
        name: "",
        thumbnail: ""
      };
    });

    const { mutate, isLoading } = useMutation(
      () => {
        return ShoppableService.addLibrary(toFormData(library.value));
      },
      {
        onValidate() {
          return formRef.value.validate();
        },
        onSuccess() {
          close();
          reset();
          emit("updated");
        },
        onError(e) {
          showToastActionAlert(context, {
            message: e.message || e.response.data.message
          });
        }
      }
    );

    return {
      formRef,

      visible,
      open,
      close,

      library,
      mutate,
      isLoading
    };
  }
});
</script>
