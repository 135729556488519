<template>
  <v-dialog v-model="internalVisible" :width="'800px'" max-width="90%">
    <v-card flat>
      <v-card-title>
        <div class="font-weight-bold text-h6">Thông tin hình ảnh</div>

        <v-spacer></v-spacer>

        <v-btn icon @click="internalVisible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="mt-4 grey lighten-3 pa-5" fluid>
        <v-card flat>
          <v-form ref="formRef" lazy-validation>
            <div class="d-flex align-start pa-5">
              <image-view-config
                :image="internalImage"
                :index="index"
              ></image-view-config>

              <div class="ml-4" style="flex: 1 1 0">
                <div>
                  <div class=" mb-1">Tiêu đề hình ảnh</div>

                  <tp-text-field
                    v-model="internalImage.title"
                    placeholder="Nhập tiêu đề hình ảnh"
                    validate="required"
                  ></tp-text-field>
                </div>

                <div>
                  <div class=" mb-1">
                    <span>Khuyến mại</span>

                    <a
                      v-if="internalImage.promotion_id"
                      :href="
                        `/#/promotion/detail/${internalImage.promotion_id}`
                      "
                      class="ml-2 primary--text"
                      style="font-size: 12px"
                      target="_blank"
                    >
                      Link
                    </a>
                  </div>

                  <tp-autocomplete
                    v-model="internalImage.promotion_id"
                    :items="promotions"
                    clearable
                    item-text="name"
                    item-value="id"
                    no-data-text="Không có dữ liệu"
                    placeholder="Chọn khuyến mại"
                    single-line
                  ></tp-autocomplete>
                </div>

                <div>
                  <div class="font-weight-bold mb-2">Thời gian áp dụng</div>

                  <div>
                    <div class="mb-2">Bắt đầu</div>

                    <div class="d-flex align-center">
                      <div>
                        <tp-input-time
                          v-model="timeFrom"
                          custom-class="text-center text-body-1 mr-3 mt-0"
                          custom-style="width: 65px"
                          dense
                          hide-details
                          outlined
                          validate="required"
                        ></tp-input-time>
                      </div>

                      <div>
                        <tp-input-date
                          :value="internalImage.time_from"
                          custom-class="text-body-1 mt-0"
                          dense
                          hide-details
                          outlined
                          placeholder="Chọn ngày"
                          prepend-inner-icon="mdi-calendar"
                          @change="
                            internalImage.time_from = setTime($event, timeFrom)
                          "
                        ></tp-input-date>
                      </div>
                    </div>

                    <div class="mt-4">
                      <div class="d-flex flex-row mb-2">
                        <div>
                          <v-checkbox
                            v-model="hasTimeTo"
                            :false-value="false"
                            :true-value="true"
                            class="mb-0 mt-0 pt-0"
                            dense
                            hide-details
                          ></v-checkbox>
                        </div>

                        <div>Kết thúc</div>
                      </div>

                      <div v-if="hasTimeTo" class="d-flex align-center">
                        <div>
                          <tp-input-time
                            v-model="timeTo"
                            custom-class="text-center text-body-1 mr-3 mt-0"
                            custom-style="width: 65px"
                            dense
                            hide-details
                            outlined
                          ></tp-input-time>
                        </div>

                        <div>
                          <tp-input-date
                            :value="internalImage.time_to"
                            custom-class="text-body-1 mt-0"
                            dense
                            hide-details
                            outlined
                            placeholder="Chọn ngày"
                            prepend-inner-icon="mdi-calendar"
                            @change="
                              internalImage.time_to = setTime($event, timeTo)
                            "
                          ></tp-input-date>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-form>
        </v-card>
      </v-container>

      <v-card-actions>
        <v-btn color="primary" depressed @click="onSaveImage">Lưu ảnh</v-btn>
        <v-btn color="primary" depressed @click="onDeleteImage">Xoá ảnh</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent, getCurrentInstance, ref, watch } from "vue";
import { useVModel } from "@vueuse/core";
import ImageViewConfig from "./image-view-config.vue";
import dayjs from "dayjs";
import { usePromotionData } from "../composables";
import { showToastActionAlert } from "@/core/composables";

export default defineComponent({
  components: { ImageViewConfig },

  props: {
    visible: { type: Boolean, required: true },
    image: { type: Object, required: true },
    index: { type: Number, required: true }
  },

  setup(props, { emit }) {
    const context = getCurrentInstance().proxy;
    const { promotions } = usePromotionData();
    const internalVisible = useVModel(props, "visible", emit, {
      eventName: "update:visible"
    });

    const formRef = ref(null);
    const internalImage = ref({ ...props.image });

    watch(
      () => props.visible,
      value => {
        if (value) {
          internalImage.value = {
            ...props.image
          };
        }
      }
    );

    watch(
      () => internalImage.value.promotion_id,
      () => {
        internalImage.value.points = [];
      }
    );

    const onSaveImage = () => {
      const isValid = formRef.value.validate();
      const isValidPoints = internalImage.value.points.length;

      if (!isValid) return;
      if (!isValidPoints) {
        showToastActionAlert(context, {
          message: "Vui lòng chọn vị trí product cho shoppable"
        });

        return;
      }

      emit("update:image", internalImage.value);
      emit("update", internalImage.value);
    };

    const onDeleteImage = () => {
      context.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xác nhận xoá hình ảnh <strong>${internalImage.value.title}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: () => {
              emit("delete", internalImage.value);
            }
          }
        }
      });
    };

    return {
      formRef,
      promotions,
      internalVisible,
      internalImage,
      onSaveImage,
      onDeleteImage
    };
  },

  data() {
    return {
      hasTimeTo: Boolean(this.image.time_to)
    };
  },

  computed: {
    timeFrom: {
      get() {
        return this.getTime(this.internalImage.time_from);
      },
      set(val) {
        this.internalImage.time_from = this.setTime(
          this.internalImage.time_from,
          val
        );
      }
    },

    timeTo: {
      get() {
        return this.getTime(this.internalImage.time_to);
      },
      set(val) {
        this.internalImage.time_to = this.setTime(
          this.internalImage.time_to,
          val
        );
      }
    }
  },

  watch: {
    hasTimeTo(value) {
      if (!value) {
        this.timeTo = "";
      }
    }
  },

  methods: {
    getTime(date) {
      return date ? dayjs(date).format("HH:mm") : "00:00";
    },

    setTime(date, time) {
      let dateTime = date ? new Date(date) : new Date();
      const [hours, mins] = time.split(":");

      dateTime.setHours(parseInt(hours));
      dateTime.setMinutes(parseInt(mins));

      return dayjs(dateTime).format("YYYY-MM-DD HH:mm:ss");
    }
  }
});
</script>
