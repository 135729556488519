<template>
  <v-dialog v-model="internalVisible" :width="'800px'" max-width="90%">
    <v-card>
      <v-card-title>
        <div class="font-weight-bold">Chọn sản phẩm</div>

        <v-spacer></v-spacer>

        <v-btn icon @click="internalVisible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <div class="pa-4 mt-4">
        <tp-autocomplete
          v-model="state.sku_id"
          :filter="customFilter"
          :items="productItems"
          class="tp-filter-autocomplete"
          clearable
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          placeholder="Tìm kiếm theo tên hoặc mã SKU sản phẩm"
          single-line
        ></tp-autocomplete>
      </div>

      <v-card-actions>
        <v-btn
          class="mr-3"
          color="primary"
          depressed
          outlined
          @click="onRemovePoint"
        >
          Xoá point
        </v-btn>

        <v-btn color="primary" depressed @click="onAdd">Lưu</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent, reactive, watch } from "vue";
import { useVModel } from "@vueuse/core";

export default defineComponent({
  props: {
    visible: { type: Boolean, required: true },
    point: { type: Object, default: null },
    productItems: { type: Array, default: () => [] }
  },

  setup(props, { emit }) {
    const internalVisible = useVModel(props, "visible", emit, {
      eventName: "update:visible"
    });

    const state = reactive({
      sku_id: null
    });

    watch(internalVisible, value => {
      if (!value) {
        emit("close");
      } else {
        state.sku_id = props.point?.sku_id || null;
      }
    });

    const onReset = () => {
      state.sku_id = null;
    };

    const onAdd = () => {
      if (state.sku_id) {
        emit("change", state.sku_id);

        internalVisible.value = false;
        onReset();
      }
    };

    const onRemovePoint = () => {
      emit("remove");
      internalVisible.value = false;
      onReset();
    };

    return {
      internalVisible,
      state,
      onAdd,
      onRemovePoint
    };
  },

  methods: {
    customFilter(item, queryText) {
      if (!item) return false;

      queryText = queryText || "";

      return (
        item.SKU?.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) ||
        item.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
      );
    }
  }
});
</script>
